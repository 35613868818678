import React from "react";
import {AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineInfoCircle, AiOutlineWarning, AiOutlineClose} from 'react-icons/ai';
import './Notification.css';

const Notification = ({
    type = "info",
    message,
    onClose = () => {},
    closeNotification,
}) => {

    const iconStyles = {marginRight: "10px"};

    const icons = {
        success: <AiOutlineCheckCircle style={iconStyles}/>,
        info: <AiOutlineInfoCircle style={iconStyles}/>,
        warning: <AiOutlineWarning style={iconStyles}/>,
        error: <AiOutlineCloseCircle style={iconStyles}/>
    };

    return (
        <div className={`notification ${type}`}>
            {icons[type]}
            {message}
            <AiOutlineClose color="white" className="closeBtn" onClick={() => {
                onClose();
                closeNotification();
            }}/>
        </div>
    )
}

export default Notification;