import React from "react";

const Login = ({ handleOnSubmit, authDetails, activeUserData }) => {

  return (
    <form id="login" onSubmit={handleOnSubmit}>
      <div className="mb-3">
        <label className="form-label label-bold">Email address</label>
        <input
          type="email"
          placeholder="hello@gmail.com"
          className="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          name="email"
          defaultValue={authDetails?.email}
          required
        />
        <div id="emailHelp" className="form-text">
          We'll never share your email with anyone else.
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label label-bold">Password</label>
        <input
          type="password"
          placeholder="*******"
          className="form-control"
          id="exampleInputPassword1"
          name="password"
          defaultValue={authDetails?.password}
          required
        />
      </div>
      <div className="d-grid gap-2 col-6 mx-auto text-center">
        <button type="submit" className="btn btn-primary btn-lg login-button">
          {activeUserData?.isLoading ? (
            <div className="spinner-border text-light" role="status">
              <span className="sr-only"></span>
            </div>
          ) : (
            `Sign In`
          )}
        </button>
      </div>
    </form>
  );
};

export default Login;
