import React, { createContext, useState } from 'react';

export const UserCartContext = new createContext({});

const UserCartContextProvider = ({children}) => {

    const [userCartContextData, setUserCartContextData] = useState({cart: []});

    return (
        <UserCartContext.Provider value={{userCartContextData, setUserCartContextData}} >
            {children}
        </UserCartContext.Provider>
    )
}

export default UserCartContextProvider;