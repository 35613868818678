import React from "react";

const Register = ({ handleOnSubmit, authDetails, activeUserData }) => {
  return (
    <form id="register" onSubmit={handleOnSubmit}>
      <div className="mb-3">
        <label className="form-label label-bold">Full Name</label>
        <input
          type="text"
          placeholder="Alice Novice Ryan"
          className="form-control"
          id="exampleInputFullName"
          aria-describedby="fullName"
          name="fullName"
          defaultValue={authDetails?.fullName}
        />
      </div>
      <div className="mb-3">
        <label className="form-label label-bold">Mobile Number</label>
        <input
          type="text"
          placeholder="+91 - 99** *** *04"
          className="form-control"
          id="exampleInputMobile"
          aria-describedby="mobile"
          name="mobile"
          defaultValue={authDetails?.mobile}
        />
      </div>
      <div className="mb-3">
        <label className="form-label label-bold">Email address</label>
        <input
          type="email"
          placeholder="hello@gmail.com"
          className="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          name="registrationEmail"
          defaultValue={authDetails?.registrationEmail}
        />
        <div id="emailHelp" className="form-text">
          We'll never share your email with anyone else.
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label label-bold">Password</label>
        <input
          type="password"
          placeholder="*******"
          className="form-control"
          id="exampleInputPassword1"
          name="registrationPassword"
          defaultValue={authDetails?.registrationPassword}
        />
      </div>
      <div className="d-grid gap-2 col-6 mx-auto text-center">
        <button type="submit" className="btn btn-primary btn-lg login-button">
          {activeUserData?.isLoading ? (
            <div className="spinner-border text-light" role="status">
              <span className="sr-only"></span>
            </div>
          ) : (
            `Register`
          )}
        </button>
      </div>
    </form>
  );
};

export default Register;
