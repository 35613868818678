import React from 'react';

import '../../js/jquery.min.js';
import '../../js/bootstrap.bundle.min.js';
import '../../js/jquery.sticky.js';
import '../../js/click-scroll.js';
import '../../js/custom.js';
import '../../css/bootstrap.min.css';
import '../../css/bootstrap-icons.css';
import '../../css/templatemo-topic-listing.css';
import '../../index.css';

import Logo from '../../images/Logo1.png';
import Img1 from '../../images/topics/undraw_Remote_design_team_re_urdx.png';
import Img2 from '../../images/topics/undraw_Finance_re_gnv2.png';
// import './App.css';

import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer.jsx';


const Home = () => {
    return (
        <div id="top">

            <main>

                <Navbar Logo={Logo} />

                <section className="hero-section d-flex justify-content-center align-items-center" id="section_1">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-8 col-12 mx-auto">
                                <h1 className="text-white text-center">Media | VFX | IT Services </h1>

                                <h6 className="text-center">platform for creatives around the world</h6>

                                <form method="get" className="custom-form mt-4 pt-2 mb-lg-0 mb-5" role="search">
                                    <div className="input-group input-group-lg"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-around'
                                        }}>
                                        <span className="input-group-text bi-search" id="basic-addon1">

                                        </span>

                                        <input name="keyword" type="search" className="form-control disabledInput" id="keyword"
                                            disabled placeholder="Any queries? Get in touch with experts ..."
                                            aria-label="Search" />
                                        <a href="#section_4"><button type="submit" id="submit" className="form-control">
                                            Get in touch</button></a>

                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </section>


                <section className="featured-section">
                    <div className="container">
                        <div className="row justify-content-center">

                            <div className="col-lg-4 col-12 mb-4 mb-lg-0">
                                <div className="custom-block bg-white shadow-lg">
                                    {/* <a href="topics-detail.html"> */}
                                    <div className="d-flex">
                                        <div>
                                            <h5 className="mb-2">IT services</h5>

                                            <p className="mb-0">
                                                At Ankmit we provide a comprehensive suite of IT services designed to meet
                                                the evolving needs
                                                of businesses in the digital age.
                                            </p>
                                            <p className="mb-0">Our innovative solutions in Cloud
                                                Computing and Artificial
                                                Intelligence (AI) are tailored to drive growth, streamline operations, and
                                                help you stay
                                                ahead in a competitive market.
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>
                                        <a href="itdetails" className="btn custom-btn mt-2 mt-lg-3">Learn
                                            More</a>
                                    </div>

                                    {/* </a> */}
                                </div>
                            </div>

                            <div className="col-lg-6 col-12">
                                <div className="custom-block custom-block-overlay">
                                    <div className="d-flex flex-column h-100">
                                        <img src={Img1}
                                            className="custom-block-image img-fluid" alt="" />

                                        <div className="custom-block-overlay-text d-flex">
                                            <div>
                                                <h5 className="text-white mb-2">Media</h5>

                                                <p className="text-white"> We work in a Linear rights, Non linear rights, Remake
                                                    rights, Animation & Integration, Rights Acuqistion, Music, etc. in our Media
                                                    business unit.</p>

                                                <p className="text-white"> There are lot more information available for every
                                                    service. To know more about all of them click on below button.</p>

                                                <p className="text-white"> To know more about all of them click on below button.</p>


                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}>
                                                    <a href="media" className="btn custom-btn mt-2 mt-lg-3">Learn
                                                        More</a>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="section-overlay"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


                <section className="explore-section section-padding" id="section_2">
                    <div className="container">
                        <div className="row">

                            <div className="col-12 text-center">
                                <h2 className="mb-4">Browse Services</h2>
                            </div>

                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="media-tab" data-bs-toggle="tab"
                                        data-bs-target="#media-tab-pane" type="button" role="tab" aria-controls="media-tab-pane"
                                        aria-selected="true">Media</button>
                                </li>

                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="it-tab" data-bs-toggle="tab" data-bs-target="#it-tab-pane"
                                        type="button" role="tab" aria-controls="it-tab-pane" aria-selected="false">Information
                                        Technology</button>
                                </li>

                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="mediadigital-tab" data-bs-toggle="tab"
                                        data-bs-target="#mediadigital-tab-pane" type="button" role="tab"
                                        aria-controls="mediadigital-tab-pane" aria-selected="false">Marketing & Digital...</button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">

                            <div className="col-12">
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="media-tab-pane" role="tabpanel"
                                        aria-labelledby="media-tab" tabIndex="0">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                                                <div className="custom-block bg-white shadow-lg">
                                                    <a href="media">
                                                        <div className="d-flex">
                                                            <div>
                                                                <h5 className="mb-2">Linear Rights</h5>

                                                                <p className="mb-0">
                                                                    Linear Rights refer to the permissions and licenses required
                                                                    for traditional broadcast distribution, such as television
                                                                    networks and cable channels.
                                                                </p>
                                                            </div>

                                                        </div>

                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                                                <div className="custom-block bg-white shadow-lg">
                                                    <a href="media">
                                                        <div className="d-flex">
                                                            <div>
                                                                <h5 className="mb-2">Non linear rights</h5>

                                                                <p className="mb-0">
                                                                    Non Linear Rights pertain to the licensing and distribution
                                                                    of content on digital platforms, including streaming
                                                                    services and video-on-demand platforms, such as Zee 5,
                                                                    Netflix, Amazon Prime Video, or Hulu, as well as
                                                                    transactional VOD platforms like iTunes or Google Play.
                                                                </p>
                                                            </div>

                                                        </div>

                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6 col-12">
                                                <div className="custom-block bg-white shadow-lg">
                                                    <a href="media">
                                                        <div className="d-flex">
                                                            <div>
                                                                <h5 className="mb-2">Remake rights</h5>

                                                                <p className="mb-0">
                                                                    Remake rights allow for the creation of new versions or
                                                                    adaptations of existing media properties, requiring legal
                                                                    permissions to reproduce and distribute the content with
                                                                    creative modifications.
                                                                </p>
                                                            </div>

                                                        </div>

                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-5">
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                                                <div className="custom-block bg-white shadow-lg">
                                                    <a href="media">
                                                        <div className="d-flex">
                                                            <div>
                                                                <h5 className="mb-2">Animation & Integration</h5>

                                                                <p className="mb-0">
                                                                    Animate the 3D models to ensure they move realistically
                                                                    within the scenes.
                                                                    Integrate the VFX and 3D elements seamlessly with the
                                                                    live-action footage
                                                                </p>
                                                            </div>

                                                        </div>

                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                                                <div className="custom-block bg-white shadow-lg">
                                                    <a href="media">
                                                        <div className="d-flex">
                                                            <div>
                                                                <h5 className="mb-2">Rights Acuqistion</h5>

                                                                <p className="mb-0">
                                                                    Our primary focus is acquiring rights for content from
                                                                    different states and languages. We engage in negotiations
                                                                    with production houses, distributors, and rights holders to
                                                                    secure the necessary permissions and licenses.
                                                                </p>
                                                            </div>

                                                        </div>


                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6 col-12">
                                                <div className="custom-block bg-white shadow-lg">
                                                    <a href="media">
                                                        <div className="d-flex">
                                                            <div>
                                                                <h5 className="mb-2">Music</h5>

                                                                <p className="mb-0">
                                                                    Licensing music rights ensures legal
                                                                    compliance and compensates the rights holders for the use of
                                                                    their copyrighted works in films, TV shows, commercials, and
                                                                    other audiovisual projects.
                                                                </p>
                                                            </div>

                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="it-tab-pane" role="tabpanel" aria-labelledby="it-tab"
                                        tabIndex="0">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-3">
                                                <div className="custom-block bg-white shadow-lg">
                                                    <a href="itdetails">
                                                        <div className="d-flex">
                                                            <div>
                                                                <h5 className="mb-2">Cloud computing solutions</h5>

                                                                <p className="mb-0">We provide various services like below: </p>
                                                                <ul>
                                                                    <li>Infrastructure as a Service (IaaS)</li>
                                                                    <li>Platform as a Service (PaaS)</li>
                                                                    <li>Software as a Service (SaaS)</li>
                                                                </ul>
                                                            </div>

                                                        </div>

                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-3">
                                                <div className="custom-block bg-white shadow-lg">
                                                    <a href="itdetails">
                                                        <div className="d-flex">
                                                            <div>
                                                                <h5 className="mb-2">Artificial Intelligence (AI) Solutions</h5>

                                                                <p className="mb-0">We provide various services like below:</p>
                                                                <ul>
                                                                    <li>AI-powered solutions for importers, exporters, and
                                                                        infrastructure businesses</li>
                                                                    <li>Digital platform for business expansion and growth</li>
                                                                    <li>Data analytics and insights for informed decision-making
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </div>

                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="mediadigital-tab-pane" role="tabpanel"
                                        aria-labelledby="mediadigital-tab" tabIndex="0">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-6 col-md-6 col-12 mb-4 mb-lg-0">
                                                <div className="custom-block bg-white shadow-lg">
                                                    <a href="mediadigital">
                                                        <div className="d-flex">
                                                            <div>
                                                                <h5 className="mb-2">Marketing & Digital intermediate and Grading</h5>

                                                                <p className="mb-0">Ankmit is focused on helping clients grow their business through our various digital marketing website services. This includes their website, search engine rankings, social media impact, digital advertising and online reputation management.</p>
                                                            </div>

                                                        </div>

                                                        <img src={Img2}
                                                            className="custom-block-image img-fluid" alt="" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>


                <section className="timeline-section section-padding" id="section_3">
                    <div className="section-overlay"></div>

                    <div className="container">
                        <div className="row">

                            <div className="col-12 text-center">
                                <h2 className="text-white mb-4">How does it work?</h2>
                            </div>

                            <div className="col-lg-10 col-12 mx-auto">
                                <div className="timeline-container">
                                    <ul className="vertical-scrollable-timeline" id="vertical-scrollable-timeline">
                                        <div className="list-progress">
                                            <div className="inner"></div>
                                        </div>

                                        <li>
                                            <h4 className="text-white mb-3">Got any requiements?</h4>

                                            <p className="text-white">
                                                When you need someone to assist with you for your requirements on Media, VFX, IT related services?
                                            </p>

                                            <div className="icon-holder">
                                                <i className="bi bi-question-lg"></i>
                                            </div>
                                        </li>

                                        <li>
                                            <h4 className="text-white mb-3">Get your ideas & Connect</h4>

                                            <p className="text-white">
                                                When you got your ideas / requirements and you contact us we will assist you with our dedicated customer facilitator to facilitate you with our services
                                            </p>

                                            <div className="icon-holder">
                                                <i className="bi bi-person-lines-fill"></i>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div className="col-12 text-center mt-5">
                                <p className="text-white">
                                    Want to know more?
                                    <a href="#section_4" className="btn custom-btn custom-border-btn ms-3">Get in touch</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="contact-section section-padding section-bg" id="section_4">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-12 col-12 text-center">
                                <h2 className="mb-5">Get in touch</h2>
                            </div>

                            <div className="col-lg-6 col-12 mb-6 mb-lg-0">
                                {/* <iframe className="google-map"
            src="https://maps.google.com/maps?q=19.186784740965233, 72.83573277970434&z=15&output=embed"
            width="100%" height="250" allowFullSreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            </div>

                            {/* <!-- 19.186784740965233, 72.83573277970434 --> */}

                            <div className="col-lg-4 col-md-6 col-12 mb-3 mb-lg- mb-md-0 ms-auto">
                                <h4 className="mb-3">Head office</h4>

                                <p>507, Palm Spring,
                                    Link Road, Malad west
                                    Mumbai-400064</p>
                                <hr />

                                <p className="d-flex align-items-center mb-1">
                                    <span className="me-2">Phone</span>

                                    <a href="tel: +91-9892129298" className="site-footer-link">
                                        +91-9892129298
                                    </a>
                                </p>

                                <p className="d-flex align-items-center">
                                    <span className="me-2">Email</span>

                                    <a href="mailto:admin@ankmitinnovations.com" className="site-footer-link">
                                        admin@ankmitinnovations.com
                                    </a>
                                </p>
                            </div>

                        </div>
                    </div>
                </section>
            </main >

            <Footer Logo={Logo} />

        </div >
    );
}

export default Home;