import React from "react";

import Logo from '../../images/Logo1.png';
import media2 from '../../images/media2.jpg';
import rearViewYoungCollegeStudent from '../../images/rear-view-young-college-student.jpg';

import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const ITDetails = () => {
    return (
        <div id="top">

            <main>

                <Navbar Logo={Logo} />

                <header className="site-header d-flex flex-column justify-content-center align-items-center">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">

                            <div className="col-lg-5 col-12 mb-5">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">Homepage</a></li>

                                        <li className="breadcrumb-item active" aria-current="page">IT Services</li>
                                    </ol>
                                </nav>

                                <h2 className="text-white">Introduction to <br /> IT Services</h2>

                                <div className="d-flex align-items-center mt-5">
                                    <a href="#topics-detail" className="btn custom-btn custom-border-btn smoothscroll me-4">Read
                                        More</a>

                                </div>
                            </div>

                            <div className="col-lg-5 col-12">
                                <div className="topics-detail-block bg-white shadow-lg">
                                    <img src={media2} className="topics-detail-block-image img-fluid" alt="media"/>
                                </div>
                            </div>

                        </div>
                    </div>
                </header>

                <section className="topics-detail-section section-padding" id="topics-detail">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-8 col-12 m-auto">
                                <h3 className="mb-4">Introduction to IT services</h3>

                                <p>At <strong>Ankmit</strong> we provide a comprehensive suite of IT services designed to meet the evolving needs
                                    of businesses in the digital age. Our innovative solutions in Cloud Computing and Artificial
                                    Intelligence (AI) are tailored to drive growth, streamline operations, and help you stay
                                    ahead in a competitive market. Discover how our expertise can transform your business.</p>

                                <hr />

                                <div className="col-lg-12 col-12 m-auto">

                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <blockquote>Cloud Computing Solutions</blockquote>
                                    </div>

                                    <p>Unlock the full potential of your business with our state-of-the-art Cloud Computing Solutions. Whether you need scalable infrastructure, a robust development platform, or ready-to-use software applications, we’ve got you covered. Our cloud services include:</p>

                                    <div className="col-lg-12 col-12 m-auto">
                                        <h5 className="mb-4">Infrastructure as a Service (IaaS)</h5>
                                        <p>Build a flexible and scalable IT infrastructure with our IaaS solutions. From virtual machines and storage to networking and security, we provide the foundation you need to run your applications and manage your data efficiently.</p>
                                    </div>
                                    <div className="col-lg-12 col-12 m-auto">
                                        <h5 className="mb-4">Platform as a Service (PaaS)</h5>
                                        <p>Accelerate your development process with our PaaS offerings. Our platform provides the tools and services you need to develop, deploy, and manage applications seamlessly. Focus on your code while we handle the infrastructure, middleware, and development tools.</p>
                                    </div>
                                    <div className="col-lg-12 col-12 m-auto">
                                        <h5 className="mb-4">Software as a Service (SaaS)</h5>
                                        <p>Access a wide range of software applications hosted on the cloud with our SaaS solutions. Enjoy the convenience of subscription-based services with automatic updates, maintenance, and support, all without the hassle of traditional software management.</p>
                                    </div>
                                </div>

                                <hr />

                                <div className="col-lg-12 col-12 m-auto">

                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <blockquote>Artificial Intelligence (AI) Solutions</blockquote>
                                    </div>

                                    <p>Empower your business with our advanced Artificial Intelligence (AI) Solutions. Our AI-driven services are tailored to meet the unique challenges of importers, exporters, and infrastructure businesses, offering you innovative tools for growth and success.</p>

                                    <div className="col-lg-12 col-12 m-auto">
                                        <h5 className="mb-4">AI-Powered Solutions for Importers, Exporters, and Infrastructure Businesses</h5>
                                        <p>Leverage AI technologies to streamline your operations, enhance efficiency, and gain a competitive edge. Our solutions include intelligent automation, predictive analytics, and advanced data processing to address your specific industry needs.</p>
                                    </div>
                                    <div className="col-lg-12 col-12 m-auto">
                                        <h5 className="mb-4">Digital Platform for Business Expansion and Growth</h5>
                                        <p>Expand your reach and grow your business with our comprehensive digital platform. We provide tools and strategies designed to support your business goals, from market analysis and customer engagement to online presence and brand development.</p>
                                    </div>
                                    <div className="col-lg-12 col-12 m-auto">
                                        <h5 className="mb-4">Data Analytics and Insights for Informed Decision-Making</h5>
                                        <p>Make smarter decisions with our data analytics services. We offer in-depth analysis and actionable insights derived from your data to help you understand trends, identify opportunities, and make strategic decisions for your business’s future.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="section-padding section-bg">
                    <div className="container">
                        <div className="row justify-content-center">

                            <div className="col-lg-5 col-12">
                                <img src={rearViewYoungCollegeStudent} className="newsletter-image img-fluid" alt="" />
                            </div>

                            <div className="col-lg-5 col-12 subscribe-form-wrap d-flex justify-content-center align-items-center">
                                <form className="custom-form subscribe-form" action="#" method="post">
                                    <h4 className="mb-4 pb-2">Get Newsletter</h4>

                                    <input type="email" name="subscribe-email" id="subscribe-email" pattern="[^ @]*@[^ @]*"
                                        className="form-control" placeholder="Email Address" required="" />

                                    <div className="col-lg-12 col-12">
                                        <button type="submit" className="form-control">Subscribe</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </section>

            </main>

            < Footer Logo={Logo} />

        </div>
    )
};

export default ITDetails;