import React from "react";

const WithoutVariant = ({ AddToCart, id, title, shortDescription }) => {
    return (
        <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0" key={id}>
            <div className="custom-block custom-flex-block bg-white shadow-lg">
                {/* <a href="media"> */}
                <div className="d-flex">
                    <div>
                        <h5 className="mb-2">{title}</h5>

                        <p className="mb-0">
                            {shortDescription}
                        </p>
                    </div>
                </div>
                { AddToCart && <AddToCart id={id} />}

                {/* </a> */}
            </div>
        </div>

    )
}

export default WithoutVariant;