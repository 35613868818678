import React from "react";
import { useLocation } from "react-router-dom";

const Footer = ({ Logo }) => {

    const location = useLocation();
    // console.log("location: ", location);
    return (
        <footer className="site-footer section-padding">
            <div className="container">
                <div className="row">

                    <div className="col-lg-3 col-12 mb-4 pb-2">
                        <a className="navbar-brand mb-2" href="/">
                            <img src={Logo} alt="" style={{ width: '12rem' }} />
                        </a>
                    </div>

                    <div className="col-lg-3 col-md-4 col-6">
                        <h6 className="site-footer-title mb-3">Resources</h6>

                        <ul className="site-footer-links">
                            <li className="site-footer-link-item">
                                {
                                    location.pathname === '/' ? (
                                        <a href='#section_1' className="site-footer-link">Home</a>
                                    ) : (
                                        <a href="/" className="site-footer-link">Home</a>
                                    )
                                }
                            </li>

                            <li className="site-footer-link-item">
                                <a href="/media" className="site-footer-link">Media</a>
                            </li>

                            <li className="site-footer-link-item">
                                <a href="/itdetails" className="site-footer-link">IT services</a>
                            </li>

                            <li className="site-footer-link-item">
                                <a href="/mediadigital" className="site-footer-link">Media & Digital</a>
                            </li>

                            <li className="site-footer-link-item">
                                {
                                    location.pathname === '/' ? (
                                        <a href='#section_4' className="site-footer-link">Contact</a>
                                    ) : (
                                        <a href='/' className="site-footer-link">Contact</a>
                                    )
                                }
                            </li>
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-4 col-6 mb-4 mb-lg-0">
                        <h6 className="site-footer-title mb-3">Contact Information</h6>

                        <p className="text-white d-flex mb-1">

                            <a href="tel: +91-9892129298" className="site-footer-link">
                                <i className="bi bi-telephone-outbound"></i> +91-9892129298
                            </a>
                        </p>

                        <p className="text-white d-flex">
                            <a href="mailto:admin@ankmitinnovations.com" className="site-footer-link">
                                <i className="bi bi-envelope"></i>
                                admin@ankmitinnovations.com
                            </a>
                        </p>
                    </div>

                    <div className="col-lg-3 col-md-4 col-12 mt-4 mt-lg-0 ms-auto">

                        <p className="copyright-text mt-lg-5 mt-4">Copyright © 2024 Ankmit Innovations Private Limited - All
                            Rights Reserved.
                        </p>

                    </div>

                </div>
            </div>
        </footer>
    )
};

export default Footer;