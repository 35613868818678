import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  getDocs,
} from "firebase/firestore";

import Logo from "../../images/Logo1.png";

import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Login from "../../components/login/Login";
import Register from "../../components/register/Register";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import useNotification from "../../hooks/useNotification";

const Authenticate = ({ firebaseDB }) => {

  const {NotificationComponent, triggerNotification} = useNotification("top-right");

  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const [authDetails, setAuthDetails] = useState(null);

  const [isActiveUser, setIsActiveUser] = useLocalStorage("activeUser", {
    isLogin: false,
    isLoading: false,
    isError: false,
    cart: [],
    email: ''
  });

  const toggleLogin = (type) => {
    setIsLogin(type === "login");
    setAuthDetails({});
  };

  const handleOnSubmit = async (event, operation = "login") => {
    event.preventDefault();
    setIsActiveUser({
      isError: false,
      isLogin: false,
      isLoading: true,
      cart: [],
      email: ''
    });
    try {
      const formData = new FormData(event.target);
      let dataValues = {};
      for (let [key, value] of formData.entries()) {
        dataValues = {
          ...dataValues,
          [key]: value,
        };
      }
      try {
        const authenticationSnapshot = await getDocs(
          collection(firebaseDB, "authentication")
        );

        const authenticationLists = await authenticationSnapshot.docs.map(
          (doc) => {
            return doc.data();
          }
        );

        // console.log(authenticationLists);

        const existingAuthenticationLists = authenticationLists.filter(
          (data) => {
            return operation === "registration"
              ? data.registrationEmail === dataValues.registrationEmail
              : data.registrationEmail === dataValues.email &&
                  data.registrationPassword === dataValues.password;
          }
        );

        if (operation === "registration") {
          // add logic
          if (existingAuthenticationLists.length === 0) {
            const docRef = await addDoc(
              collection(firebaseDB, "authentication"),
              dataValues
            );
            setIsActiveUser({
              isError: false,
              isLogin: false,
              isLoading: false,
              cart: [],
              email: ''
            });
          } else {
            setIsActiveUser({
              isLoading: false,
              isError: true,
              isLogin: false,
              cart: [],
              existingAuthenticationLists: [],
            });
            triggerNotification({
              type: 'error',
              message: "you're already registered with us, please sign in",
              onClose: () => {
                setIsLogin(true);
              },
              duration: 30000
            });
          }
        }
        if (operation === "login") {
          if (existingAuthenticationLists.length === 1) {
            setIsActiveUser({
              isError: false,
              isLogin: true,
              isLoading: false,
              cart: [],
              existingAuthenticationLists: [{
                fullName: existingAuthenticationLists[0].fullName,
                mobile: existingAuthenticationLists[0].mobile,
                registrationEmail: existingAuthenticationLists[0].registrationEmail
              }]
            });
          } else {
            setIsActiveUser({
              isLoading: false,
              isError: true,
              isLogin: false,
              cart: [],
              existingAuthenticationLists: [],
            });
            triggerNotification({
              type: 'error',
              message: "you're not registered with us, please register",
              onClose: () => {
                setIsLogin(false);
              },
              duration: 30000
            });
          }
        }
      } catch (error) {
        setIsActiveUser({
          isError: true,
          isLogin: false,
          isLoading: false,
          cart: [],
          existingAuthenticationLists: [],
        });
      }
      setAuthDetails({});
      event.target.reset();
    } catch (e) {
      // console.log("error while submitting form: ", e);
      setIsActiveUser({
        isError: true,
        isLogin: false,
        isLoading: false,
        cart: [],
        email: ''
      });
    }
  };

  useEffect(() => {
    if (isActiveUser?.isLogin) {
      navigate("/services", { replace: true });
      navigate(0);
    }
  }, [isActiveUser?.isLogin, navigate]);

  return (
    <div id="top">
      {NotificationComponent}
      <main>
        <Navbar Logo={Logo} />

        <header className="site-header d-flex flex-column justify-content-center align-items-center">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-4 col-12 mb-5">
                <h2 className="text-white">Authenticate Yourself</h2>
              </div>

              <div className="col-lg-1"></div>

              <div className="col-lg-5 col-12">
                <div className="topics-detail-block bg-white shadow-lg">
                  <div className="card card-border">
                    <div className="card-header login-header">
                      <ul className="nav nav-pills card-header-pills">
                        <li
                          className="nav-item"
                          onClick={() => {
                            toggleLogin("login");
                          }}
                        >
                          <a className={`nav-link ${isLogin && "active"}`}>
                            Sign In
                          </a>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => {
                            toggleLogin("register");
                          }}
                        >
                          <a className={`nav-link ${!isLogin && "active"}`}>
                            Register
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="card-body">
                      {isLogin ? (
                        <Login
                          handleOnSubmit={($event) =>
                            handleOnSubmit($event, "login")
                          }
                          activeUserData={isActiveUser}
                          authDetails={authDetails}
                        />
                      ) : (
                        <Register
                          handleOnSubmit={($event) =>
                            handleOnSubmit($event, "registration")
                          }
                          authDetails={authDetails}
                          activeUserData={isActiveUser}
                        />
                      )}

                      {/* register */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </main>

      <Footer Logo={Logo} />
    </div>
  );
};

export default Authenticate;
