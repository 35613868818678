import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Navbar = ({ Logo }) => {
  const activeUserData = JSON.parse(localStorage.getItem("activeUser"));

  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("Nav use effect");
    const homeLink = document.getElementById("home_anchor");
    const output = homeLink.classList.remove("active");
  }, []);

  const navigateTo = (path) => {
    if (pathname === '/') {
      if (path === 'home') {
        navigate("#section_1");
      }
      if (path === 'browseService') {
        navigate("#section_2");
      }
      if (path === 'work') {
        navigate("#section_3");
      }
      if (path === 'contact') {
        navigate("#section_4");
      }
    }
    if (pathname !== '/') {
      navigate("/", { replace: true });
      navigate(0);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src={Logo} alt="" style={{ width: "4rem" }} />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-lg-5 me-lg-auto">
            <li className="nav-item">
              <a href="/" className="nav-link click-scroll" id="home_anchor" onClick={() => { navigateTo('home') }}>
                Home
              </a>
            </li>

            <li className="nav-item">
              <a href="/" className="nav-link click-scroll" onClick={() => { navigateTo('browseService') }}>
                Browse Services
              </a>
            </li>

            <li className="nav-item">
              <a href="/" className="nav-link click-scroll" onClick={() => { navigateTo('work') }}>
                How we work
              </a>
            </li>

            <li className="nav-item">
              <a href="/" className="nav-link click-scroll" onClick={() => { navigateTo('contact') }}>
                Contact
              </a>
            </li>

            <li className="nav-item">
              {location.pathname === "/" && !activeUserData?.isLogin ? (
                <Link
                  to="/authenticate"
                  reloadDocument={true}
                  className="nav-link click-scroll"
                >
                  Sign In / Sign Up
                </Link>
              ) : null}
            </li>

            <li className="nav-item">
              {activeUserData?.isLogin ? (
                <a
                  href="/services"
                  className={`nav-link click-scroll ${location?.pathname === "/services" && "active"
                    }`}
                >
                  Services
                </a>
              ) : null}
            </li>

            <li className="nav-item">
              {activeUserData?.isLogin ? (
                <a
                  href="/cart"
                  className={`nav-link click-scroll ${location?.pathname === "/cart" && "active"
                    }`}
                >
                  Cart
                </a>
              ) : null}
            </li>

            <li className="nav-item">
              {activeUserData?.isLogin ? (
                <Link
                  to="/"
                  reloadDocument={true}
                  className="nav-link click-scroll"
                  onClick={() => {
                    localStorage.setItem("activeUser", JSON.stringify({}));
                  }}
                >
                  Logout
                </Link>
              ) : null}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

// import { Link, useMatch, useResolvedPath } from "react-router-dom";
// import "../../js/jquery.min.js";
// import "../../js/bootstrap.bundle.min.js";
// import "../../js/jquery.sticky.js";
// import "../../js/click-scroll.js";
// import "../../js/custom.js";
// import "../../css/bootstrap.min.css";
// import "../../css/bootstrap-icons.css";
// import "../../css/templatemo-topic-listing.css";

// export default function Navbar({ Logo }) {
//   return (
//     // <nav className="nav">
//     //   <Link to="/" className="site-title">
//     //     Site Name
//     //   </Link>
//     //   <ul>
//     //     <CustomLink to="/pricing">Pricing</CustomLink>
//     //     <CustomLink to="/about">About</CustomLink>
//     //   </ul>
//     // </nav>
//     <nav className="navbar navbar-expand-lg">
//       <div className="container">
//         <a className="navbar-brand" href="/">
//           <img src={Logo} alt="" style={{ width: "4rem" }} />
//         </a>

//         <button
//           className="navbar-toggler"
//           type="button"
//           data-bs-toggle="collapse"
//           data-bs-target="#navbarNav"
//           aria-controls="navbarNav"
//           aria-expanded="false"
//           aria-label="Toggle navigation"
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>

//         <div className="collapse navbar-collapse" id="navbarNav">
//           <ul className="navbar-nav ms-lg-5 me-lg-auto">
//             <CustomLink to="/">Home</CustomLink>
//             <CustomLink to="/">Browse Services</CustomLink>
//             <CustomLink to="/media">How we work</CustomLink>
//             <CustomLink to="/">Contact</CustomLink>
//             <CustomLink to="/authenticate">Sign In / Sign Up</CustomLink>
//             <CustomLink to="/services">Services</CustomLink>
//             <CustomLink
//               to="/"
//               onClick={() => {
//                 localStorage.setItem("activeUser", JSON.stringify({}));
//               }}
//             >
//               Logout
//             </CustomLink>
//           </ul>
//         </div>
//       </div>
//     </nav>
//   );
// }

// function CustomLink({ to, children, ...props }) {
//   const resolvedPath = useResolvedPath(to);
//   const isActive = useMatch({ path: resolvedPath.pathname, end: true });

//   return (
//     <li className="nav-item">
//       <Link
//         to={to}
//         {...props}
//         className={`nav-link click-scroll ${isActive} ? 'active': ''`}
//       >
//         {children}
//       </Link>
//     </li>
//   );
// }
